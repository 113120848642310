import React, { useEffect, useState } from "react";

import {
  Box,
  Container,
  Select,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";

import { BOINGO_FIBER_AMOUNT } from "../assets/constant";

export default function ProductSection({
  finalCustomerInfo,
  setFinalCustomerInfo,
}) {
  const [discountInfo, setDiscountInfo] = useState("");

  useEffect(() => {
    if (finalCustomerInfo && finalCustomerInfo.promo_code_success) {
      setDiscountInfo(finalCustomerInfo.promo_code_success);
    }
  }, [finalCustomerInfo]);
  return (
    <>
      {/* {JSON.stringify(finalCustomerInfo)} */}
      <Box sx={{ backgroundColor: "#e0edf5" }}>
        <TableContainer>
          <Table
            className="table-wrapper"
            sx={{
              width: { xs: "95%", md: "450px", lg: "750px" },
              margin: "auto",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  className="product-title"
                  sx={{
                    padding: "15px 0 0 0",
                    borderBottom: "0px",
                  }}
                >
                  Boingo Fiber
                </TableCell>

                <TableCell
                  className="product-title"
                  sx={{ padding: "15px 0 0 0", borderBottom: "0px" }}
                  align="right"
                >
                  {finalCustomerInfo &&
                  finalCustomerInfo.promo_code_success &&
                  discountInfo ? (
                    <>
                      <span className="amount-off">
                        ${discountInfo.discount_amount.toFixed(2)}
                      </span>
                      ${discountInfo.final_price.toFixed(2)}
                    </>
                  ) : (
                    `$${BOINGO_FIBER_AMOUNT.toFixed(2)}`
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  className="description"
                  sx={{ padding: "0 0 15px 0", borderBottom: "0px" }}
                >
                  Up to 1 Gig per second
                </TableCell>
                <TableCell
                  className="description"
                  sx={{ padding: "0 0 15px 0", borderBottom: "0px" }}
                  align="right"
                >
                  {discountInfo ? "first month" : "per month"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
