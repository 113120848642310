import { Typography } from "@mui/material";
import redBoingo from "../assets/images/boingo_logo_red.png";
import { Box } from "@mui/system";

export default function Footer() {
  return (
    <>
      <Box className="footer" sx={{ textAlign: "center" }}>
        <Box className="footLogo">
          <img src={redBoingo} className="boingo-logo-bottom" alt="logo" />
        </Box>
      </Box>
    </>
  );
}
