import { createTheme, ThemeProvider } from "@mui/material";

const boingoTheme = createTheme({
  palette: {
    primary: {
      main: "#d42b1e", //boingo-red
      light: "#63b8ff",
      dark: "#3d434a", //boingo-grey
      contrastText: "#fff",
    },
    secondary: {
      main: "#41c0e2", //boingo-blue
      light: "#82e9de",
      dark: "#00867d",
      contrastText: "#000",
    },
    error: {
      main: "#d42b1e",
    },
  },
  typography: {
    fontFamily: "FigTree-Regular, Helvetica, Sans-Serif",
    ...["h1", "h2", "h3", "h4", "h5", "h6"].reduce(
      (acc, key) => ({
        ...acc,
        [key]: {
          fontFamily: "Museo900",
          color: "#3d434a",
        },
      }),
      {}
    ),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "20px",
          fontFamily: "FigTree-Medium",
          textTransform: "none",
          // background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
          // borderRadius: 3,
          // border: 0,
          // color: "white",
          // height: 48,
          // padding: "0 30px",
          // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
          "&:hover": {
            background: "var(--darkblue)",
            transition: "all .3s ease-in-out",
          },
        },
      },
    },
    // MuiAlert: {
    //   styleOverrides: {
    //     root: {
    //       color: "#fff",
    //       backgroundColor: "transparent",
    //       borderRadius: "10px",
    //     },
    //   },
    // },
    MuiInputBase: {
      styleOverrides: {
        root: {
          // borderRadius: "10px !important",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          //backgroundColor: "green",
          //borderRadius: "5px",
          //borderRight: "0px solid green",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          // Add other styles if needed
          "&.Mui-selected": {
            backgroundColor: "#f7f0e6",
            "&:hover": {
              backgroundColor: "#f7f0e6",
            },
          },
        },
      },
    },
  },
});

export default boingoTheme;
