import React, { useState } from "react";

import { Container, Box, Typography } from "@mui/material";
import { eventApiWrapperV2Service } from "../services/apiServices";

import "../assets/stylesheets/notAvailable.css";

import { content } from "../assets/content";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function NotAvailable({
  register,
  handleSubmit,
  watch,
  errors,
  addressInfo,
  setAddressInfo,
  finalCustomerInfo,
  setFinalCustomerInfo,
}) {
  const [formErrorPosting, setFormErrorPosting] = useState(false);

  const onSubmitForm = async (data) => {
    eventApiWrapperV2Service(data)
      .then((response) => {
        console.log("Successful posted to eventapi v2: ", response.data);
        window.location.hash = "/stay-tuned";
      })
      .catch((error) => {
        console.log("Failed to post to eventApiv2 :", error.response.data);
        setFormErrorPosting(true);
      });
  };
  return (
    <>
      <Header />
      <Box className="container not-available">
        <Box className="wrapper">
          <Box className="body-wrap">
            <Typography className="title">
              Sorry, we don’t offer service to your home yet.
            </Typography>
            <Typography className="subtitle">
              Provide a bit more information and we’ll update you as soon as
              it’s available.
            </Typography>

            <form onSubmit={handleSubmit(onSubmitForm)}>
              <input
                {...register("first_name", {
                  required: true,
                  maxLength: 25,
                  pattern: /^[A-Za-z\s,-,.]+$/i,
                })}
                placeholder="First Name"
              />
              {errors.first_name && (
                <p className="input-error">{content.fNameError}</p>
              )}

              <input
                {...register("last_name", {
                  required: true,
                  maxLength: 25,
                  pattern: /^[A-Za-z\s,-,.]+$/i,
                })}
                placeholder="Last Name"
              />
              {errors.last_name && (
                <p className="input-error">{content.lNameError}</p>
              )}

              <input
                {...register("email", {
                  required: true,
                  pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                })}
                placeholder="Email Address"
              />
              {errors.email && (
                <p className="input-error">{content.emailError}</p>
              )}
              <select {...register("countryCode")}>
                <option value="+1">USA (+1)</option>
              </select>
              <input
                className="phoneNum"
                {...register("phone", {
                  required: true,
                  pattern: /^\+?[\d\s-()]{1,20}$/i,
                })}
                placeholder="Phone Number"
              />
              {errors.phone && (
                <p className="input-error">{content.phoneNumberError}</p>
              )}

              <input type="submit" value="Submit" />

              {formErrorPosting && (
                <p className="error">There is an issue with your form</p>
              )}
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
}
