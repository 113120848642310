import React, { useEffect, useState } from "react";
import braintree from "braintree-web";
import {
  Box,
  Container,
  Select,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";

import { getBraintreeAuthTokenFromDomain } from "../config/config";

export default function BriantreeHostedField({
  braintreeInstanceObject,
  setBraintreeInstanceObject,
  deviceDataKount,
  setDeviceDataKount,
  paymentAndLoginValidationErrors,
  setPaymentAndLoginValidationErrors,
}) {
  const [isCCNumFocused, setIsCCNumFocused] = useState(false);
  const [isCCNumFilled, setIsCCNumFilled] = useState(false);
  const [isCCNumError, setIsCCNumError] = useState(false);

  const [isCVVFocused, setIsCVVFocused] = useState(false);
  const [isCVVFilled, setIsCVVFilled] = useState(false);
  const [isCVVError, setIsCVVError] = useState(false);

  const [isExpDateFocused, setIsExpDateFocused] = useState(false);
  const [isExpDateFilled, setExpDateFilled] = useState(false);
  const [isExpDateError, setIsExpDateError] = useState(false);

  const [isPostalCodeFocused, setIsPostalCodeFocused] = useState(false);
  const [isPostalCodeFilled, setIsPostalCodeFilled] = useState(false);
  const [isPostalCodeError, setIsPostalCodeError] = useState(false);

  useEffect(() => {
    tokenizeFields();
  }, []);

  const tokenizeFields = (event) => {
    braintree.client.create(
      {
        authorization: getBraintreeAuthTokenFromDomain(window.location.host),
      },
      function (clientErr, clientInstance) {
        if (clientErr) {
          console.error("Error creating hostedField client:", clientErr);
          return;
        }

        braintree.dataCollector.create(
          {
            client: clientInstance,
            kount: true,
          },
          function (err, dataCollectorInstance) {
            if (err) {
              console.log(err);
              // Handle error in creation of data collector
              return;
            }
            setDeviceDataKount(dataCollectorInstance.deviceData);
          }
        );

        braintree.hostedFields.create(
          {
            client: clientInstance,
            styles: {
              input: {
                "font-family":
                  "Figtree-Regular, Helvetica, Verdana, sans-serif",
                color: "#9c9c9c",
                "font-size": "16px !important",
                "max-height": "100px",
              },
              "input.invalid": {
                color: "#d52b1e",
              },
              "input.valid": {
                color: "#555",
              },
            },
            fields: {
              number: {
                selector: "#card-number",
                // Other field options
              },
              cvv: {
                selector: "#cvv",
                //placeholder: "123",
              },
              expirationDate: {
                selector: "#expiration-date",
                // placeholder: "10/2032",
              },
              postalCode: {
                selector: "#cc-postalCode",
              },
            },
          },
          function (hostedFieldsErr, hostedFieldsInstance) {
            if (hostedFieldsErr) {
              console.error("Error creating Hosted Fields:", hostedFieldsErr);
              return;
            }

            // Add event listeners to handle focus and blur
            hostedFieldsInstance.on("focus", (event) => {
              switch (event.emittedBy) {
                case "number":
                  setIsCCNumFocused(true);
                  break;
                case "cvv":
                  setIsCVVFocused(true);
                  break;
                case "expirationDate":
                  setIsExpDateFocused(true);
                  break;
                case "postalCode":
                  setIsPostalCodeFocused(true);
                  break;
                default:
                  break;
              }
            });

            hostedFieldsInstance.on("blur", (event) => {
              let error = "";
              let isValid = event.fields[event.emittedBy].isValid;
              switch (event.emittedBy) {
                case "number":
                  setIsCCNumFocused(false);
                  let isCCNumError = !isValid;
                  setIsCCNumError(isCCNumError);
                  error = isCCNumError ? "Invalid Credit Card Number" : "";
                  break;
                case "cvv":
                  setIsCVVFocused(false);
                  let isCVVError = !isValid;
                  setIsCVVError(isCVVError);
                  error = isCVVError ? "Invalid CVV" : "";
                  break;
                case "expirationDate":
                  setIsExpDateFocused(false);
                  let isExpDateError = !isValid;
                  setIsExpDateError(isExpDateError);
                  error = isExpDateError ? "Invalid Expiration Date" : "";
                  break;
                case "postalCode":
                  setIsPostalCodeFocused(false);
                  let isPostalCodeError = !isValid;
                  setIsPostalCodeError(isPostalCodeError);
                  error = isPostalCodeError ? "Invalid Postal Code" : "";
                  break;
                default:
                  break;
              }
              setPaymentAndLoginValidationErrors((prevErrors) => ({
                ...prevErrors,
                [event.emittedBy]: error,
              }));
            });

            hostedFieldsInstance.on("notEmpty", (event) => {
              switch (event.emittedBy) {
                case "number":
                  setIsCCNumFilled(true);
                  break;
                case "cvv":
                  setIsCVVFilled(true);
                  break;
                case "expirationDate":
                  setExpDateFilled(true);
                  break;
                case "postalCode":
                  setIsPostalCodeFilled(true);
                  break;
                  defaut: break;
              }
            });
            setBraintreeInstanceObject(hostedFieldsInstance);
          }
        );
      }
    );
  };

  return (
    <>
      <Box component="form" className="payment-form">
        {/* Card Number */}
        <FormControl fullWidth variant="outlined">
          <InputLabel
            shrink={isCCNumFocused || isCCNumFilled}
            className={`${isCCNumFocused ? "focused" : ""} ${
              isCCNumError ? "invalid" : "valid"
            }`}
          >
            Credit Card Number *
          </InputLabel>
          <div
            id="card-number"
            className={`hosted-field ${isCCNumFocused ? "focused" : ""}  ${
              isCCNumError ? "invalid" : ""
            }`}
          ></div>

          {isCCNumError ? (
            <span className="error">Enter a valid credit card</span>
          ) : (
            ""
          )}
        </FormControl>

        {/* CVV */}
        <FormControl fullWidth variant="outlined">
          <InputLabel
            shrink={isCVVFocused || isCVVFilled}
            className={`${isCVVFocused ? "focused" : ""} ${
              isCVVError ? "invalid" : "valid"
            }`}
          >
            CVV *
          </InputLabel>
          <div
            id="cvv"
            className={`hosted-field ${isCVVFocused ? "focused" : ""} ${
              isCVVError ? "invalid" : ""
            } `}
          ></div>
          {isCVVError ? <span className="error">Invalid CVV</span> : ""}
        </FormControl>

        {/* Exipration Date */}
        <FormControl fullWidth variant="outlined">
          <InputLabel
            shrink={isExpDateFocused || isExpDateFilled}
            className={`${isExpDateFocused ? "focused" : ""} ${
              isExpDateError ? "invalid" : "valid"
            }`}
          >
            Expiration Date (MM/YY) *
          </InputLabel>
          <div
            id="expiration-date"
            className={`hosted-field ${isExpDateFocused ? "focused" : ""} ${
              isExpDateError ? "invalid" : ""
            }`}
          ></div>
          {isExpDateError ? (
            <span className="error">Expiration Date is invalid</span>
          ) : (
            ""
          )}
        </FormControl>

        {/* Postal Code */}
        <FormControl fullWidth variant="outlined">
          <InputLabel
            shrink={isPostalCodeFocused || isPostalCodeFilled}
            className={`${isPostalCodeFocused ? "focused" : ""} ${
              isPostalCodeError ? "invalid" : "valid"
            }`}
          >
            Postal Code *
          </InputLabel>
          <div
            id="cc-postalCode"
            className={`hosted-field ${isPostalCodeFocused ? "focused" : ""} ${
              isPostalCodeError ? "invalid" : ""
            }`}
          ></div>
          {isPostalCodeError ? (
            <span className="error">Postal Code is invalid</span>
          ) : (
            ""
          )}
        </FormControl>
      </Box>
    </>
  );
}
