import React, { useState } from "react";

import { Container, Box, Typography } from "@mui/material";
import { eventApiWrapperV2Service } from "../services/apiServices";

import "../assets/stylesheets/stayTuned.css";

import { content } from "../assets/content";
import FiberLogo from "../assets/images/Boingo-Fiber-Logo-Horizontal.svg";
import StayTunedImg from "../assets/images/stay-tuned.svg";
export default function StayTuned() {
  return (
    <>
      <Box className="container stay-tuned">
        <Box className="stay-tuned-yeah-girl"></Box>
        <Box sx={{ margin: "auto" }}>
          <img src={StayTunedImg} className="stay-tuned-img" />
        </Box>
        <Typography className="stay-tuned-title">
          We’ll let you know when Boingo Fiber is ready in your
          area.
        </Typography>
        <Box sx={{ height: "20vh" }}>
          <img src={FiberLogo} className="fiber-logo" width="300px" />
        </Box>
      </Box>
    </>
  );
}
