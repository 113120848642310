// SubmitButton.js
import React, { useEffect, useState } from "react";
import { FIBER_PRODUCT_CODE, ARCARS_VENUE_ID, ARCARS_SIGNUP_SOURCE } from "../assets/constant";
import { createCustomerService } from "../services/apiServices";
import { generateGUID, deleteCookieByName } from "../config/utils";
import {
  Modal,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
import FiberLogo from "../assets/images/Boingo-Fiber-Logo-Horizontal.svg";

function SubmitButtonForSignup({
  finalCustomerInfo,
  braintreeInstanceObject,
  deviceDataKount,
  paymentType,
  setPaymentType,
  milstarCCGlobal,
  setMilstarCCGlobal,
  paymentAndLoginValidationErrors,
}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setIsLoading(false);
    setErrorMessage(null);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useEffect(() => {
    if (paymentType === "applePay" || paymentType === "PayPal") {
      setIsSubmitDisabled(false);
    }
    if (paymentType === "hostedField" || paymentType === "milstar") {
      setIsSubmitDisabled(true);
      // Define all the keys that should be in paymentAndLoginValidationErrors, (values are same for hostedfields and milstar)
      const requiredErrorKeys = [
        "number",
        "cvv",
        "expirationDate",
        "postalCode",
        "username",
        "password",
      ];

      // Check if all keys are set in paymentValidationErrors
      const allKeysSet = requiredErrorKeys.every(
        (key) => key in paymentAndLoginValidationErrors
      );

      if (allKeysSet) {
        // Check if all error messages are empty
        const allErrorsResolved = Object.values(
          paymentAndLoginValidationErrors
        ).every((error) => error === "");
        setIsSubmitDisabled(!allErrorsResolved);
      }
    }
  }, [paymentAndLoginValidationErrors, paymentType]);

  const handleSignupAndPayment = async (event) => {
    event.preventDefault();

    handleOpen();
    setIsLoading(true);

    let paymentTypeObj = {};

    // If User selects Milstar payment
    if (paymentType === "milstar") {
      paymentTypeObj.payment_cc = milstarCCGlobal;
      createCustomerWithPayment(paymentTypeObj);
    } else if (paymentType === "applePay" || paymentType === "PayPal") {
      paymentTypeObj.payment_braintree = {
        ...braintreeInstanceObject,
        device_data: deviceDataKount,
      };
      createCustomerWithPayment(paymentTypeObj);
    }
    // If User selects Braintree Hosted Fields (default)
    else if (paymentType === "hostedField") {
      braintreeInstanceObject.tokenize(async function (tokenizeErr, payload) {
        if (tokenizeErr) {
          console.error("Error hosted fields tokenizing:", tokenizeErr);
          setErrorMessage(tokenizeErr.message);
          setIsLoading(false);
          return;
        }

        paymentTypeObj.payment_braintree = {
          nonce: payload.nonce,
          payment_type: "Credit Card",
          device_data: deviceDataKount,
        };
        createCustomerWithPayment(paymentTypeObj);
      });
      return false;
    }
  };

  const createCustomerWithPayment = async (paymentObj) => {
    //prepare customer data object

    let customerDataObj = {
      ...finalCustomerInfo,
      ...paymentObj,
      product_codes: [FIBER_PRODUCT_CODE],
      transaction_id: generateGUID(),
      scc: ARCARS_SIGNUP_SOURCE,
      venue_id: ARCARS_VENUE_ID,
      GSID: generateGUID().toUpperCase(),
    };

    createCustomerService(customerDataObj)
      .then((response) => {
        console.log("Successful customer creation: ", response.data);
        deleteCookieByName("customerData");
        window.location.hash = "/fiber-welcome";
      })
      .catch((error) => {
        console.log("Failed to create customer: ", error);
        setIsLoading(false);
        error.message && error.response.data
          ? setErrorMessage(error.response.data.message)
          : setErrorMessage(error.message);
      });
  };

  return (
    <>
      {/* {JSON.stringify(paymentType)} */}
      {/* {JSON.stringify(paymentAndLoginValidationErrors)} */}
      {/* {JSON.stringify(finalCustomerInfo)} */}

      <Button
        disabled={isSubmitDisabled || isLoading}
        sx={{ mb: "100px", width: "100%" }}
        disableElevation
        variant="contained"
        type="submit"
        onClick={handleSignupAndPayment}
      >
        Complete signup
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <img src={FiberLogo} alt="Fiber Logo" />
        </DialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: "center" }}>
            {isLoading && <CircularProgress sx={{ m: 3 }} />}
            <Typography sx={{ mt: 2 }}>
              Please wait while we create your account
            </Typography>
            {errorMessage && (
              <>
                <Typography sx={{ mt: 2 }} color="primary">
                  {errorMessage}
                </Typography>

                <Button sx={{ margin: "15px auto 0" }} onClick={handleClose}>
                  Close
                </Button>
              </>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default SubmitButtonForSignup;
