import { Box, Container, Select, TextField, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Link, NavLink, useLocation } from "react-router-dom";

export default function Breadcrumbs() {
  const location = useLocation();
  return (
    <>
      <Box
        className="breadcrumbs"
        sx={{
          display: "flex",
          alignItems: "center",
          width: { xs: "95%", md: "450px", lg: "750px" },
        }}
      >
        <NavLink to="/check-availability" className="fiber-plan-link">
          Fiber Plan
        </NavLink>
        <KeyboardArrowRightIcon sx={{ color: "#d8d8d8" }} />

        <NavLink
          to="/signup-form"
          className="fiber-plan-link"
          activeclassname="active"
        >
          Account
        </NavLink>
        <KeyboardArrowRightIcon sx={{ color: "#d8d8d8" }} />

        {location.pathname === "/payment" ? (
          <NavLink
            to="/payment"
            className="fiber-plan-link"
            activeclassname="active"
          >
            Checkout
          </NavLink>
        ) : (
          <span className="fiber-plan-link">Checkout</span>
        )}
      </Box>
    </>
  );
}
