import React, { useEffect, useState } from "react";
import braintree from "braintree-web";
import {
  Box,
  Container,
  Select,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";

import { validateInput } from "../config/utils";

export default function MilstarPayment({
  milstarCCGobal,
  setMilstarCCGlobal,
  paymentAndLoginValidationErrors,
  setPaymentAndLoginValidationErrors,
}) {
  const [milstarLocal, setMilStarLocal] = useState({
    credit_card_number: "",
    cvv2: "",
    credit_card_expiration: "",
    postal_code: "",
  });

  const milStarNumRegex = /^[6][0][1][9][4][45]\d*/g;
  const cvvRegex = /^\d{3,4}$/;
  const postalRegex = /^(\d{5}[-+]\d{4})|^(\d{5})$/;

  {
    /* Use name from payment input to set state */
  }
  const handleChange = (event) => {
    let { name, value } = event.target;

    const updatedMilstar = {
      ...milstarLocal,
      [name]: value,
    };

    setMilStarLocal(updatedMilstar);

    setMilstarCCGlobal({
      ...updatedMilstar,
    });
  };

  const milstarValidation = (val, regex) => {
    return validateInput(val, regex);
  };

  {
    /* Use ID from payment input to set error */
  }
  const handleBlur = (event) => {
    const { id, value } = event.target;
    let error = "";

    switch (id) {
      case "number":
        if (!milstarValidation(value, milStarNumRegex)) {
          error = "Invalid Milstar Card";
        }
        break;
      case "cvv":
        if (!milstarValidation(value, cvvRegex)) {
          error = "Invalid CVV";
        }
        break;
      case "expirationDate":
        let exMonth = value.substring(0, 2);
        let exYear = value.substring(2, 4);
        let expire = new Date("20" + exYear, exMonth - 1, "01"); // subtract 1 from month because JavaScript months are 0-based
        let today = new Date();
        if (today <= expire && exMonth <= 12) {
          error = "";
        } else {
          error = "Invalid expiration date";
        }
        break;
      case "postalCode":
        if (!milstarValidation(value, postalRegex)) {
          error = "Invalid Postal Code";
        }
        break;

      default:
        break;
    }

    setPaymentAndLoginValidationErrors({
      ...paymentAndLoginValidationErrors,
      [id]: error,
    });
  };

  return (
    <>
      <Box component="form" className="payment-form">
        {/* Card Number */}
        <TextField
          className="milstar-input"
          required
          fullWidth
          type="number"
          margin="dense"
          variant="outlined"
          id="number"
          name="credit_card_number"
          label="Credit Card Number"
          value={milstarLocal.number}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!paymentAndLoginValidationErrors.number}
          helperText={paymentAndLoginValidationErrors.number}
          color="secondary"
        />

        <TextField
          className="milstar-input"
          required
          fullWidth
          type="number"
          margin="dense"
          variant="outlined"
          id="cvv"
          name="cvv2"
          label="CVV"
          value={milstarLocal.cvv}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!paymentAndLoginValidationErrors.cvv}
          helperText={paymentAndLoginValidationErrors.cvv}
          color="secondary"
        />

        <TextField
          className="milstar-input"
          required
          fullWidth
          margin="dense"
          variant="outlined"
          id="expirationDate"
          name="credit_card_expiration"
          label="Expiration Date (MMYY)"
          value={milstarLocal.expirationDate}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!paymentAndLoginValidationErrors.expirationDate}
          helperText={paymentAndLoginValidationErrors.expirationDate}
          color="secondary"
        />

        <TextField
          className="milstar-input"
          required
          fullWidth
          type="number"
          margin="dense"
          variant="outlined"
          id="postalCode"
          name="postal_code"
          label="Postal Code"
          value={milstarLocal.postalCode}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!paymentAndLoginValidationErrors.postalCode}
          helperText={paymentAndLoginValidationErrors.postalCode}
          color="secondary"
        />
      </Box>
    </>
  );
}
