import { content } from "../assets/content";

export default function Header() {
  return (
    <>
      <header className="App-header">
        <div className="fiberLogo"></div>
        {/* <p className="sub-header">
          {content.header} <span>{content.header2}</span>
        </p> */}
      </header>
    </>
  );
}
