import React, { useState, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import { fetchAddressService } from "../services/apiServices";
import { deleteCookieByName } from "../config/utils";
import { content } from "../assets/content";
import Header from "../components/Header";
import Footer from "../components/Footer";

import { SITE_SUBDIVISION } from "../assets/address";

import { Link } from "react-router-dom";

import {
  Container,
  Paper,
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Button,
  TextField,
} from "@mui/material";

export default function AddressQualifications({
  register,
  handleSubmit,
  watch,
  errors,
  addressInfo,
  setAddressInfo,
  modemData,
  setModemData,
}) {
  const [addressData, setAddressData] = useState(null);
  const [site, setSite] = useState("");
  const [streetName, setStreetName] = useState("");
  const [streetType, setStreetType] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [unit, setUnit] = useState("");

  const [sites, setSites] = useState([]);
  const [streetNames, setStreetNames] = useState([]);
  const [streetTypes, setStreetTypes] = useState([]);
  const [houseNumbers, setHouseNumbers] = useState([]);
  const [units, setUnits] = useState([]);

  const loadDataFromApi = async () => {
    // setAddressData(SITE_SUBDIVISION);

    fetchAddressService()
      .then((response) => {
        const responseData = response.data;
        const addresses = responseData.map((address, index) => ({
          id: index,
          ...address,
        }));
        setAddressData(addresses);
      })
      .catch((error) => {
        console.error("Error fetching address:", error);
      });
  };

  //function  returns object of id, ont_mac, fsan, based upon selected address of site, street_name, street_type, house_number, unit
  const createComparator = (fields, source) => (item) =>
    fields.every((field) => item[field] === source[field]);

  const onSubmitForm = (event) => {
    event.preventDefault(); // Prevent the default form submission

    //Peform API call for addres qualifications
    const formData = {
      site: site,
      street_name: streetName,
      street_type: streetType,
      house_number: houseNumber,
      unit: unit,
    };

    setAddressInfo(formData);
    window.location.hash = "/signup-form";
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
    //delete customerData on load
    deleteCookieByName("customerData");
  }, []);

  useEffect(() => {
    loadDataFromApi();
  }, []);

  useEffect(() => {
    if (addressData) {
      const newSites = Array.from(
        new Set(addressData.map((item) => item["site"]))
      );
      setSites(newSites);
    }
  }, [addressData]);

  useEffect(() => {
    if (addressData && site) {
      const newStreetNames = Array.from(
        new Set(
          addressData
            .filter((item) => item["site"] === site)
            .map((item) => item["street_name"])
        )
      );
      setStreetNames(newStreetNames);
    }
  }, [addressData, site]);

  //useEffect for streetTypes having Selector dropdown
  // useEffect(() => {
  //   if (addressData && site && streetName) {
  //     const newStreetTypes = Array.from(
  //       new Set(
  //         addressData
  //           .filter(
  //             (item) =>
  //               item["site"] === site && item["street_name"] === streetName
  //           )
  //           .map((item) => item["street_type"])
  //       )
  //     );
  //     setStreetTypes(newStreetTypes);
  //   }
  // }, [addressData, site, streetName]);

  //Handler that automatically set streetType
  const streetNameHandler = (e) => {
    const selectedStreetName = e.target.value;

    setStreetName(selectedStreetName);

    const filteredStreetTypes = addressData
      .filter(
        (item) => item.site === site && item.street_name === selectedStreetName
      )
      .map((item) => item.street_type);
    const uniqueStreetTypes = [...new Set(filteredStreetTypes)];
    setStreetTypes(uniqueStreetTypes);

    // Automatically set streetType if there's only one
    if (uniqueStreetTypes.length === 1) {
      setStreetType(uniqueStreetTypes[0]);
    } else {
      // Reset streetType to encourage manual selection if multiple options
      setStreetType("");
    }
  };

  useEffect(() => {
    if (addressData && site && streetName && streetType) {
      const newHouseNumbers = Array.from(
        new Set(
          addressData
            .filter(
              (item) =>
                item["site"] === site &&
                item["street_name"] === streetName &&
                item["street_type"] === streetType
            )
            .map((item) => item["house_number"])
        )
      );
      setHouseNumbers(newHouseNumbers);
    }
  }, [addressData, site, streetName, streetType]);

  useEffect(() => {
    if (addressData && site && streetName && streetType && houseNumber) {
      const newUnits = Array.from(
        new Set(
          addressData
            .filter(
              (item) =>
                item["site"] === site &&
                item["street_name"] === streetName &&
                item["street_type"] === streetType &&
                item["house_number"] === houseNumber
            )
            .map((item) => item["unit"])
        )
      );
      setUnits(newUnits);
    }
  }, [addressData, site, streetName, streetType, houseNumber]);

  // traverse through Address Data to get ONT, FSAN, ID from site,street_name, street_type, house, and unit  object
  useEffect(() => {
    if (addressData && unit) {
      console.log("Site:", site);
      console.log("Street Name:", streetName);
      console.log("Street Type:", streetType);
      console.log("House Number:", houseNumber);

      const addressQualifierSearch = {
        site: site,
        street_name: streetName,
        street_type: streetType,
        house_number: houseNumber,
        unit: unit,
      };

      const resultFromSearch = addressData.find(
        createComparator(
          ["site", "street_name", "street_type", "house_number", "unit"],
          addressQualifierSearch
        )
      );

      if (!resultFromSearch) {
        console.log("No ID, FSAN, MAC from selected address");
      } else {
        const { fsan, ont_mac: mac_address, id } = resultFromSearch;
        setModemData({ fsan, mac_address, id });

        console.log(`fsan: ${fsan}, mac: ${mac_address}, id: ${id}`);
      }
    }
  }, [unit]);

  useEffect(() => {
    setStreetName("");
    setStreetType("");
    setHouseNumber("");
    setUnit("");
  }, [site]);

  // useEffect(() => {
  //   setStreetType("");
  //   setHouseNumber("");
  //   setUnit("");
  // }, [streetName]);

  // useEffect(() => {
  //   setHouseNumber("");
  //   setUnit("");
  // }, [streetType]);

  useEffect(() => {
    setUnit("");
  }, [houseNumber]);

  return (
    <>
      <Header />
      <div className="container">
        <Grid
          container
          sx={{
            padding: "20px 20px",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography className="find-out-title">
            Find out if Boingo Fiber is available for your home.
          </Typography>
          <form onSubmit={onSubmitForm}>
            <Grid item xs={12} md={12} lg={12}>
              <FormControl
                required
                color="secondary"
                sx={{
                  width: "100%",
                  backgroundColor: "#fff",
                  marginBottom: "15px",
                }}
              >
                <InputLabel>{content.siteLabel}</InputLabel>
                <Select
                  label={content.siteLabel}
                  value={site}
                  onChange={(e) => setSite(e.target.value)}
                >
                  <MenuItem value="" disabled>
                    <em>{content.selectSite}</em>
                  </MenuItem>
                  {sites.map((site) => (
                    <MenuItem key={site} value={site}>
                      {site}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>{" "}
            </Grid>

            <Grid item xs={12}>
              <FormControl
                color="secondary"
                required
                sx={{
                  backgroundColor: "#fff",
                  width: "100%",
                  marginBottom: "15px",
                }}
              >
                <InputLabel>{content.streetNameLabel}</InputLabel>
                <Select
                  label={content.streetNameLabel}
                  value={streetName}
                  onChange={(e) => streetNameHandler(e)}
                >
                  <MenuItem value="" disabled>
                    <em>{content.selectStreetName}</em>
                  </MenuItem>
                  {streetNames.map((streetN) => (
                    <MenuItem key={streetN} value={streetN}>
                      {streetN}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {site && streetName && (
              <Grid item xs={12}>
                <TextField
                  sx={{ marginBottom: "15px" }}
                  fullWidth
                  label={"Street Type"}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={streetTypes}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl
                required
                color="secondary"
                sx={{
                  backgroundColor: "#fff",
                  width: "100%",
                  marginBottom: "15px",
                }}
              >
                <InputLabel>{content.houseNumberLabel}</InputLabel>
                <Select
                  label={content.houseNumberLabel}
                  value={houseNumber}
                  onChange={(e) => setHouseNumber(e.target.value)}
                >
                  <MenuItem value="" disabled>
                    <em>{content.selectHouseNumber}</em>
                  </MenuItem>
                  {houseNumbers.map((houseN) => (
                    <MenuItem key={houseN} value={houseN}>
                      {houseN}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                required
                color="secondary"
                sx={{
                  width: "100%",
                }}
              >
                <InputLabel>{content.unitLabel}</InputLabel>
                <Select
                  label={content.unitLabel}
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                >
                  <MenuItem value="" disabled>
                    <em>{content.selectUnit}</em>
                  </MenuItem>
                  {units.map((unt) => (
                    <MenuItem key={unt} value={unt}>
                      {unt}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Button
              sx={{ mb: "40px", width: "100%" }}
              disableElevation
              variant="contained"
              type="submit"
            >
              Check address
            </Button>
          </form>
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            Don't see your address, please{" "}
            <Link to="/registration">click here</Link>
          </Typography>
        </Grid>
      </div>
    </>
  );
}
