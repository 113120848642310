//Boingo Product Code
export const FIBER_PRODUCT_CODE =
  "DL_Fiber_1Gig_Internet_[1_Month_Recurring_69.00]";

//Fiber Pricing
export const BOINGO_FIBER_AMOUNT = 69;

//VenueID and Signup Source
export const ARCARS_VENUE_ID = 4582737;
export const ARCARS_SIGNUP_SOURCE = "BRDARCARS";
