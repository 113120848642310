import React, { useEffect, useState } from "react";
import braintree from "braintree-web";
import "../assets/stylesheets/payment.css";
import {
  Box,
  Container,
  Select,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
  InputAdornment,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Navbar from "../components/Navbar";
import Breadcrumbs from "../components/Breadcrumbs";
import PromoCode from "../components/PromoCode";
import ProductSection from "../components/ProductSection";
import BriantreeHostedField from "../components/BraintreeHostedField";
import SubmitButtonForSignup from "../components/SubmitButtonForSignup";
import { content } from "../assets/content";
import MilstarPayment from "../components/MilstarPayment";
import ApplePayPayment from "../components/ApplePayPayment";
import PaypalPayment from "../components/PaypalPayment";

import MastercardImage from "../assets/images/mastercard.svg";
import AmexImage from "../assets/images/americanexpress.svg";
import VisaImage from "../assets/images/visa.svg";
import DiscoverImage from "../assets/images/discover.svg";
import MilstarImage from "../assets/images/milstar.svg";

import { validateInput, getCookieByName } from "../config/utils";

export default function PaymentForm({
  register,
  handleSubmit,
  watch,
  errors,
  addressInfo,
  setAddressInfo,
  finalCustomerInfo,
  setFinalCustomerInfo,
}) {
  const [braintreeInstanceObject, setBraintreeInstanceObject] = useState({});
  const [deviceDataKount, setDeviceDataKount] = useState("");
  const [paymentType, setPaymentType] = useState("hostedField");
  const [milstarCCGlobal, setMilstarCCGlobal] = useState({});
  const [paymentAndLoginValidationErrors, setPaymentAndLoginValidationErrors] =
    useState({});

  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (event) => {
    setPaymentType(event.target.value);
    setPaymentAndLoginValidationErrors({});
  };

  const handleLoginInfo = (event) => {
    setFinalCustomerInfo({
      ...finalCustomerInfo,
      [event.target.id]: event.target.value,
    });
  };

  const loginFormValidation = (value, regex) => {
    return validateInput(value, regex);
  };

  const usernameRegex = /^[a-z0-9.]{4,16}$/;
  const passwordRegex = /^.{6,16}$/;

  const handleBlur = (event) => {
    const { id, value } = event.target;
    let error = "";

    switch (id) {
      case "username":
        if (!loginFormValidation(value, usernameRegex)) {
          error =
            "Between 4-16 characters, and may only contain lowercase, numbers, and periods";
        }
        break;
      case "password":
        if (!loginFormValidation(value, passwordRegex)) {
          error = "Password has to be between 6-16 characters";
        }
        break;
      default:
        break;
    }

    setPaymentAndLoginValidationErrors({
      ...paymentAndLoginValidationErrors,
      [id]: error,
    });
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);

    //if user refresh page
    const customerDataCookie = getCookieByName("customerData");
    if (customerDataCookie) {
      const customerData = JSON.parse(customerDataCookie);
      setFinalCustomerInfo((prevState) => ({
        ...prevState,
        first_name: customerData.first_name || "",
        last_name: customerData.last_name || "",
        email: customerData.email || "",
        phone_number: "1 " + customerData.phone_number.replace(/-/g, "") || "",
        address: {
          site: customerData.address.site || "",
          street_name: customerData.address.street_name || "",
          street_type: customerData.address.street_type || "",
          house_number: customerData.address.house_number || "",
          unit: customerData.address.unit || "",
        },
        modem: {
          fsan: customerData.modem.fsan || "",
          mac_address: customerData.modem.mac_address || "",
          id: customerData.modem.id || "",
        },
      }));
    }
  }, []);

  return (
    <>
      {/* {JSON.stringify(finalCustomerInfo)} */}
      {/* {JSON.stringify(paymentAndLoginValidationErrors)} */}
      <ProductSection
        finalCustomerInfo={finalCustomerInfo}
        setFinalCustomerInfo={setFinalCustomerInfo}
      />
      <Breadcrumbs />
      <Box className="signup-form-wrapper">
        <PromoCode
          finalCustomerInfo={finalCustomerInfo}
          setFinalCustomerInfo={setFinalCustomerInfo}
        />
        <Box className="user-info">
          <Typography className="payment-title" sx={{ textAlign: "left" }}>
            Choose a payment option{" "}
          </Typography>

          <Box className="express-container">
            <Typography variant="body1" className="express-title">
              Express Checkout
            </Typography>
            {window.ApplePaySession && (
              <Box id="apple-wrapper">
                <ApplePayPayment
                  braintreeInstanceObject={braintreeInstanceObject}
                  setBraintreeInstanceObject={setBraintreeInstanceObject}
                  paymentType={paymentType}
                  setPaymentType={setPaymentType}
                />
              </Box>
            )}
            <PaypalPayment
              braintreeInstanceObject={braintreeInstanceObject}
              setBraintreeInstanceObject={setBraintreeInstanceObject}
              paymentType={paymentType}
              setPaymentType={setPaymentType}
            />
          </Box>
          <Box className="separator">
            <Typography variant="body1" sx={{ mt: 2 }} className="or">
              or
            </Typography>
            <Divider className="divider" />
          </Box>

          <Box sx={{ textAlign: "left", mt: 4 }}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="paymentType"
                value={paymentType}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="hostedField"
                  control={<Radio color="secondary" />}
                  label={
                    <>
                      <img src={VisaImage} alt="Visa" />
                      &nbsp;&nbsp;
                      <img src={MastercardImage} alt="Mastercard" />
                      &nbsp;&nbsp;
                      <img src={AmexImage} alt="AmericanExpress" />
                      &nbsp;&nbsp;
                      <img src={DiscoverImage} alt="Discover" />
                    </>
                  }
                />
                <FormControlLabel
                  value="milstar"
                  control={<Radio color="secondary" />}
                  label={
                    <>
                      <img src={MilstarImage} alt="Milstar" />
                    </>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>

          {paymentType === "hostedField" && (
            <BriantreeHostedField
              braintreeInstanceObject={braintreeInstanceObject}
              setBraintreeInstanceObject={setBraintreeInstanceObject}
              deviceDataKount={deviceDataKount}
              setDeviceDataKount={setDeviceDataKount}
              paymentAndLoginValidationErrors={paymentAndLoginValidationErrors}
              setPaymentAndLoginValidationErrors={
                setPaymentAndLoginValidationErrors
              }
            />
          )}
          {paymentType === "milstar" && (
            <MilstarPayment
              milstarCCGlobal={milstarCCGlobal}
              setMilstarCCGlobal={setMilstarCCGlobal}
              paymentAndLoginValidationErrors={paymentAndLoginValidationErrors}
              setPaymentAndLoginValidationErrors={
                setPaymentAndLoginValidationErrors
              }
            />
          )}

          <Box className="login-wrapper">
            <TextField
              className="form-input"
              required
              fullWidth
              margin="dense"
              variant="outlined"
              id="username"
              label="Username"
              value={finalCustomerInfo.username}
              onChange={handleLoginInfo}
              onBlur={handleBlur}
              error={!!paymentAndLoginValidationErrors.username}
              helperText={paymentAndLoginValidationErrors.username}
              color="secondary"
            />
            <TextField
              className="form-input"
              required
              fullWidth
              margin="dense"
              variant="outlined"
              id="password"
              type={showPassword ? "text" : "password"}
              label="Password"
              value={finalCustomerInfo.password}
              onChange={handleLoginInfo}
              onBlur={handleBlur}
              error={!!paymentAndLoginValidationErrors.password}
              helperText={paymentAndLoginValidationErrors.password}
              color="secondary"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className="pw-icon"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(event) => event.preventDefault()}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box className="selected-address">
            <Typography className="header">{content.paymentAddress}</Typography>

            {finalCustomerInfo &&
              finalCustomerInfo.address &&
              Object.keys(finalCustomerInfo.address).map((key) => (
                <div key={key}>
                  <span>
                    {key
                      .replace(/_/g, " ")
                      .replace(/\b\w/g, (char) => char.toUpperCase())}
                    :
                  </span>
                  <span>{finalCustomerInfo.address[key]}</span>
                </div>
              ))}
          </Box>
          <Button
            sx={{
              mb: "100px",
              backgroundColor: "var(--blue)",
              marginBottom: "0px",
            }}
            disableElevation
            variant="contained"
            href="/#/check-availability"
          >
            Edit address
          </Button>
          <SubmitButtonForSignup
            finalCustomerInfo={finalCustomerInfo}
            braintreeInstanceObject={braintreeInstanceObject}
            deviceDataKount={deviceDataKount}
            paymentType={paymentType}
            setPaymentType={setPaymentType}
            milstarCCGlobal={milstarCCGlobal}
            setMilstarCCGlobal={setMilstarCCGlobal}
            paymentAndLoginValidationErrors={paymentAndLoginValidationErrors}
          />
        </Box>
      </Box>
    </>
  );
}
