import { Typography } from "@mui/material";
import redBoingo from "../assets/images/boingo_logo_red.png";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";

export default function WelcomeFooter() {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <Box className="welcome-footer">
        <Box className="footLogo">
          <img src={redBoingo} className="boingo-logo-bottom" alt="logo" />
        </Box>
        <Box className="footContent1">
          <Box className="footSupport">
            <Link to="/faqs">Boingo customer support</Link> is available
            24/7/365.
          </Box>
          <Box className="footLinks">
            <a
              target="_blank"
              href="https://www.boingo.com/legal/boingo-privacy-policy/"
            >
              Privacy policy
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a
              target="_blank"
              href="https://www.boingo.com/legal/website-terms-use-acceptable-use-policy/"
            >
              Terms of use
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a
              target="_blank"
              href="https://www.boingo.com/legal/cookie-policy/"
            >
              Cookie policy
            </a>
          </Box>
          <Box className="footCopyright">
            &copy; {currentYear} Boingo Wireless, Inc. All rights reserved.
          </Box>
        </Box>
      </Box>
    </>
  );
}
