import React, { useEffect, useState } from "react";
import braintree from "braintree-web";
import {
  Box,
  Container,
  Select,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";

import { getBraintreeAuthTokenFromDomain } from "../config/config";

export default function PaypalPayment({
  braintreeInstanceObject,
  setBraintreeInstanceObject,
  paymentType,
  setPaymentType,
}) {
  const getPaypalSession = () => {
    // Create a client.
    braintree.client
      .create({
        authorization: getBraintreeAuthTokenFromDomain(window.location.host),
      })
      .then(function (clientInstance) {
        // Create a PayPal Checkout component.
        return braintree.paypalCheckout.create({
          client: clientInstance,
        });
      })
      .then(function (paypalCheckoutInstance) {
        return paypalCheckoutInstance.loadPayPalSDK({
          vault: true,
        });
      })
      .then(function (paypalCheckoutInstance) {
        return window.paypal
          .Buttons({
            fundingSource: window.paypal.FUNDING.PAYPAL,

            createBillingAgreement: function () {
              return paypalCheckoutInstance.createPayment({
                flow: "vault", // Required
                intent: "capture",
              });
            },

            onApprove: function (data, actions) {
              return paypalCheckoutInstance
                .tokenizePayment(data)
                .then(function (payload) {
                  // Submit `payload.nonce` to your server

                  let paypalDetailsForBraintree = {
                    nonce: payload.nonce,
                    payment_type: "PayPal",
                  };

                  setBraintreeInstanceObject(paypalDetailsForBraintree);
                  setPaymentType("PayPal");
                  console.log("paypal payload: ", payload);
                });
            },

            onCancel: function (data) {
              console.log(
                "PayPal payment canceled",
                JSON.stringify(data, 0, 2)
              );
            },

            onError: function (err) {
              console.error("PayPal error", err);
            },
          })
          .render("#paypal-button");
      })
      .then(function () {
        // The PayPal button will be rendered in an html element with the ID
        // `paypal-button`. This function will be called when the PayPal button
        // is set up and ready to be used
        console.log("Paypal button rendered successfully");
      })
      .catch(function (err) {
        // Handle component creation error
        console.log("Braintree Paypal error:", err);
      });
  };

  useEffect(() => {
    getPaypalSession();
  }, []);

  return (
    <>
      {/* {JSON.stringify(braintreeInstanceObject)} */}
      <Box id="paypal-button"></Box>
    </>
  );
}
