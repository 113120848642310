import { content } from "../assets/content";
import { ReactComponent as YoureIn } from "../assets/images/Red-Violator-Youre-In.svg";
import { ReactComponent as BoingoFiberLogo } from "../assets/images/Boingo-Fiber-Logo-Horizontal.svg";
import { Box, Typography } from "@mui/material";

export default function Completed() {
  return (
    <>
      <header className="App-header complete"></header>
      <Box className="container complete" sx={{ textAlign: "center" }}>
        <Box className="youin-wrapper">
          <YoureIn />
        </Box>
        <Typography>{content.completedBody}</Typography>
        <Box className="logo-wrapper">
          <BoingoFiberLogo />
        </Box>
      </Box>
    </>
  );
}
