import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  InputLabel,
  Input,
  Typography,
  useTheme,
} from "@mui/material";
import { promoCodeService } from "../services/apiServices";

//constants
import { FIBER_PRODUCT_CODE, ARCARS_VENUE_ID } from "../assets/constant";

export default function PromoCode({ finalCustomerInfo, setFinalCustomerInfo }) {
  const theme = useTheme();
  const [promoCode, setPromoCode] = useState("");

  const [successPromoCodeInfo, setSuccessPromoCodeInfo] = useState("");
  const [errorPromoCodeResponse, setErrorPromoCodeResponse] = useState("");

  const handlePromoCodeChange = (event) => {
    setPromoCode(event.target.value);
  };

  const handlePromoCheck = () => {
    setSuccessPromoCodeInfo("");
    setErrorPromoCodeResponse("");

    const promoCodeData = {
      discount_code: promoCode,
      product_code: FIBER_PRODUCT_CODE,
      venue_id: ARCARS_VENUE_ID,
    };

    promoCodeService(promoCodeData)
      .then((response) => {
        console.log(response.data);

        setSuccessPromoCodeInfo(response.data);

        //set state for finalCustomerInfo for ProductSection component to update DOM
        setFinalCustomerInfo({
          ...finalCustomerInfo,
          promo_code_success: response.data,
          discount_code: promoCode,
        });
      })
      .catch((error) => {
        console.log(error);
        setErrorPromoCodeResponse(error.response);
        const updatedCustomerWithoutPromo = { ...finalCustomerInfo };
        delete updatedCustomerWithoutPromo.promo_code_success;
        delete updatedCustomerWithoutPromo.discount_code;
        setFinalCustomerInfo(updatedCustomerWithoutPromo);
      });
  };
  return (
    <>
      <Box className="promo-section" sx={{}}>
        <TextField
          className="form-input"
          required
          fullWidth
          type="text"
          margin="dense"
          variant="outlined"
          id="promo_code"
          label="Enter Promo Code"
          value={promoCode}
          onChange={handlePromoCodeChange}
          color="secondary"
          sx={{ width: "340px", marginRight: "10px" }}
        />
        <Button
          //   disabled={isSubmitDisabled || isLoading}
          className="promo-submit"
          disableElevation
          variant="contained"
          type="submit"
          onClick={handlePromoCheck}
          sx={{ width: "50px" }}
        >
          Apply
        </Button>
        <Box>
          {successPromoCodeInfo && (
            <Typography
              variant="body2"
              sx={{ fontFamily: "Figtree-Bold" }}
              color={theme.palette.secondary.main}
            >
              {successPromoCodeInfo.discount_percent}% off your first month.
            </Typography>
          )}
          {errorPromoCodeResponse && (
            <Typography variant="body2" color={theme.palette.primary.main}>
              {errorPromoCodeResponse.data.message}
            </Typography>
          )}
        </Box>
      </Box>

      {/* {JSON.stringify(finalCustomerInfo)}
      {JSON.stringify(successPromoCodeInfo)} */}
    </>
  );
}
