import axios from "axios";
import { getEnterpriseUrl } from "../config/config";

let enterpriseUrl = getEnterpriseUrl(window.location.host);

const apiService = axios.create({
  baseURL: enterpriseUrl,
  //enable for https localhost testing for apple pay, do: npm run https for https localhost testing
  //baseURL: "https://enterprise.boingogcpdev.com/ftth/api",
});

apiService.interceptors.request.use(
  (config) => {
    // Add any default headers or configurations
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

apiService.interceptors.response.use(
  (response) => {
    // Handle successful responses
    return response;
  },
  (error) => {
    // Handle errors
    if (error.response && error.response.status) {
      // Handle specific status codes if needed
      console.error("Error status:", error.response.status);
    } else {
      console.error("Error:", error.message);
    }
    return Promise.reject(error);
  }
);

export default apiService;
